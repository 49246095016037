import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Common/Navbar";
import CookieConsentForm from "./components/Common/CookieConsentForm";
import App from "./App";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { loadContent } from "./services/loadData";
import "./routes/main.css";
import "./i18n";
import Footer from "./components/FooterComponents/Footer";

const RootComponent = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(["language"]);
  const [data, setData] = useState([]);

  const load = async () => {
    let result = await loadContent(cookies["language"]);
    setData(result);
  };

  useEffect(() => {
    load();
  }, [cookies]);

  return (
    <BrowserRouter>
      <Navbar t={t} />
      <App t={t} data={data.length > 0 ? data : []} />
      <div className="line" />
      <Footer t={t} />
      <CookieConsentForm />
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
